<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-img
          class="dark align-center"
          :src="ChristmasBuddyThree"
          contain
          height="200"
        ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h1>Mission</h1>
        <p>
          The mission of Christmas Buddy is to bring the spirt of Christmas to
          the families in need during the hoildays.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ChristmasBuddyThree from '@/assets/images/ChristmasBuddy3.svg'
export default {
  name: 'ChristmasBuddy',

  data() {
    return {
      ChristmasBuddyThree: ChristmasBuddyThree,
    }
  },
}
</script>
